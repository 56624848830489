<template>
  <div>

    <v-card flat class="edit-panel">
      <div class="my-3 px-4">
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)"
          status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
          :message="successGet($options.name)" status="success"></status>
        <div class="font-regular hb-text-light">Enter Your Email Text.</div>
      </div>
      <v-container class="hb-table" v-show="!showConfirm">
        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            Select Template
          </v-col>

          <v-col class="hb-table-value">
            <span>Search for an existing template from the  template manager library</span>
            <HbAutocomplete v-model="temmplateName" :items="templateNames" v-validate="'required|max:45'" item-text='name'
            item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box" data-vv-as="Autocomplete Box"
            :error="errors.has('default.autocomplete-box')" placeholder="Select Template" class="mt-2 pl-1" single-line
            @change="appendtemplate($event)" @click:clear="clearTemplate" />
          </v-col>
        </v-row>
        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            Subject
          </v-col>

          <v-col class="hb-table-value">
            <v-text-field dense solo flat hide-details v-model.lazy="form.subject" placeholder="Enter Subject">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="hb-table-row">
          <v-col class="hb-table-value">
            <rich-text-editor  v-model="form.email" />
            <!-- <div v-else style="max-height: 200px; overflow: auto;" class="pa-3">
                                               <div style="color:black; font-family: 'Graphik Web', sans-serif;" v-html="form.email"></div>
                                                </div> -->
          </v-col>
        </v-row>

        <v-row class="mr-2 ml-2 pt-2 pb-2">
          <hb-link v-show="showMergeFields !== true" @click="showMergeFields = true" >
            Show Available Merge Fields
            <hb-tooltip>

              <template v-slot:body>
                Use Merge Fields in your message to insert
                <br />data about your tenants. By using Merge
                <br />Fields, you can send automated messages
                <br />to your tenants without having to manually
                <br />enter their information every time. Merge
                <br />Fields appear in brackets.
                <br /><br />
                In order to use a merge field simply copy
                <br />and paste the text in brackets and add into
                <br />your message.
                <br /><br />
                Example: Hello [Tenant.FirstName] [Tenant.LastName],
                <br />you owe [Tenant.TotalPastDue]. Please pay by
                <br />[Tenant.RentDueDate].
              </template>
            </hb-tooltip>
          </hb-link>

          <hb-link v-show="showMergeFields === true" @click="showMergeFields = false">
            Hide Available Merge Fields
          </hb-link>
          <merge-fields v-if="showMergeFields === true" />
        </v-row>

      </v-container>

      <v-container class="hb-table" v-show="showConfirm">
        <v-row class="hb-table-row">
          <v-col class="hb-table-label" cols="4">Person</v-col>
          <v-col class="hb-table-value text">Email</v-col>
        </v-row>
        <v-row class="hb-table-row">
          <v-col class="hb-table-label" cols="4">Total Selected</v-col>
          <v-col class="hb-table-value text">{{ this.contacts.length }}</v-col>
        </v-row>
        <v-row class="hb-table-row" v-for="contact in this.contacts" :key="contact.id">
          <v-col class="hb-table-label" cols="4">{{ contact.first }} {{ contact.last }}</v-col>
          <v-col class="hb-table-value text" v-if="contact.email">{{ contact.email }}</v-col>
          <v-col class="hb-table-value text danger-color" v-else>
            <v-alert style="font-size: 14px;" dense flat text class="ma-0" type="error">Email address missing!</v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-toolbar flat class="panel-footer" v-show="!showConfirm">
      <hb-btn icon @click="showFile = true" mdi-code="mdi-paperclip" tooltip="Attach File" :active="showFile"></hb-btn>
      <span v-if="this.attachments.length">{{ this.attachments[0].name }}</span>
      <v-spacer></v-spacer>
      <hb-btn color="secondary" @click="$emit('cancel')" class="mr-3">Back</hb-btn>
      <hb-btn color="primary" @click="setEditForm">Next</hb-btn>
    </v-toolbar>

    <v-toolbar flat class="panel-footer" v-show="showConfirm">
      <v-spacer></v-spacer>
      <hb-btn color="secondary" @click="showConfirm = false,disable = false " class="mr-3">Back</hb-btn>
      <hb-btn color="primary" :loading="loading" :disabled="disable" @click="save">Send Message</hb-btn>
    </v-toolbar>
    <add-file :show="showFile" :onClose='onFileClose' :getAttachments="getAttachments" :allowMultiple="false">
    </add-file>
  </div>
</template>

<script>
import api from '../../../assets/api.js';
import Status from '../Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import { EventBus } from '../../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import addFile from '../../communication_center/AddFile.vue'
import RichTextEditor from '../../assets/RichTextEditor.vue'
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import MergeFields from "../../includes/MergeFields";

export default {
  name: "BulkSendEmail",
  data() {
    return {
      disable: false,
      loading: false,
      temmplateName: '',
      showConfirm: false,
      form: {
        subject: '',
        email: '',
      },
      contacts: [],
      showFile: false,
      attachments: [],
      showMergeFields: false,
      templateNames: [],
      filteredList:[]
    }
  },
  mixins: [ notificationMixin ],
  props: ['items'],
  created() {
    this.getContactDetails();
  },
  components: {
    Status,
    Loader,
    HbDatePicker,
    'add-file': addFile,
    'rich-text-editor': RichTextEditor,
    MergeFields
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
    }),
  },
  mounted() {
    this.getBaseTemplateData()
  },
  methods: {
    clearTemplate() {
      this.form.email = ''
      this.form.subject = ''
    },
    async getBaseTemplateData() {
      const templates = await this.getAllTemplatesWithId([this.facilityList[0].id]); // only one property will select at a time
      const templateFilterd =templates.filter(template => template.base_template_id === null)
      this.templateNames = templateFilterd.map(template => ({ name: template.name, template_id: template.template_id }));
    },
    async getAllTemplatesWithId(properties) {
        return await this.getTemplatesById(properties);
        },
        async getTemplatesById(propertyIds) {
            try {
                const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
                const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
                return response.templates;
            } catch (error) {
                console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
                throw error; // Rethrow the error to propagate it up
            }
        },
    async appendtemplate(id) {
      try {
        await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
          if (res.template.body && res.template.subject) {
            this.form.email = res.template.body
            this.form.subject = res.template.subject
          }
          else {
            this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
          }
        })
      } catch (error) {
        throw error;
      }
    },
    onFileClose() {
      this.showFile = false
    },
    getAttachments(attachments) {
      this.attachments = attachments;
      console.log(this.attachments);
      this.onFileClose();
    },
    closeWindow() {
      this.resetAction();
    },
    async getContactDetails() {
      let items = this.items.map(item => {
        return {
          id: item.tenant_id || item.contact_id || item.lead_id,
          lease_id: item.lease_id,
        }
      });

      let response = await api.post(this, api.CONTACTS + 'bulk-config', { contact_ids: items.filter(i => i.id) });


      this.contacts = response.contacts || []

      this.filteredList = items || [];
    },
    setEditForm() {

      if (!this.form.subject) {
        this.errorSet(this.$options.name, "Please enter a subject");
        return;
      };

      if (!this.form.email) {
        this.errorSet(this.$options.name, "Please enter your message");
        return;
      };

      this.showConfirm = true;
    },
    async upload(contacts) {
      let body = {
        document_type: this.type,
        document_type_id: this.document_type_id,
        contacts: contacts
      }
      console.log("ATTACHMENTS", this.attachments)
      let response = await api.postFile(this, api.CONTACTS + '/uploads', body, this.attachments);

      return response

    },
    async save() {
      this.loading = true
      let form = {
        subject: this.form.subject,
        email: this.form.email,
        contacts: this.filteredList,
        attachments: this.attachments,
        upload_id: null,
        delivery_method: 'standard_email'
      }

      if (this.attachments.length) {
        let fileUploadResponse = await this.upload(form.contacts);
        form.upload_id = fileUploadResponse.uploads.id
        form.attachments[0].content_type = fileUploadResponse.uploads.mimetype

      }
      let response = await api.post(this, api.CONTACTS + 'bulk-send-email', form);
      if (!response) {
        this.loading = false; 
        this.disable = false;
        return;
      }
      this.successSet(this.$options.name, "Messages Sent");
      EventBus.$emit('unit_edited');
      this.loading = false; 
      this.disable = true;
    },
  }
}

</script>
<style scoped>
.strike {
  text-decoration: line-through;
  color: #a0a0a0;
}

.enhanced {
  font-weight: 500;
}

.primary-color {
  color: #00727A;

}

.panel-footer {
  border-top: none !important;
  z-index: 5;
  width: 100%;
  bottom: 0;
  position: static !important;
}
</style>
